
  import { Component } from 'vue-property-decorator'
  import { Expandable } from '@/components/toolkit/details/row/custom/Expandable'

@Component
  export default class ExpandableAddress extends Expandable {
    getStar (value) {
      if (value.default) {
        return '$g_star'
      } else {
        return '$g_star_off'
      }
    }

    async setDefault (item) {
      const { value } = this

      const personAddresses = value.filter(val => val.id !== item.id && val.default === true)

      if (personAddresses.length) {
        await Promise.all(personAddresses.map(async personAddress => {
          const fields = {
            id: personAddress.id,
            default: null,
          }
          await this.pushData({ model: 'PersonAddress', fields })
        }))
      }

      const pos = value.findIndex(val => val.id === item.id)
      if (pos >= 0) value[pos].default = !value[pos].default
      const fields = {
        id: item.id,
        default: value[pos].default ? value[pos].default : null,
      }

      await this.pushData({ model: 'PersonAddress', fields })

      value?.forEach(val => {
        if (val.id !== item.id) {
          val.default = false
        }
      })

      window.dispatchEvent(
        new CustomEvent('notification-message', {
          // @ts-ignore
          detail: {
            message: 'Dirección por defecto actualizada',
          },
        })
      )
    }

    get addresses () {
      return this.value?.sort((a, b) => {
        if (a.default) return -1
        if (b.default) return 1
        return 0
      })
    }

    get isAddresses () {
      const { value } = this
      return value?.length
    }
  }
